import React, { ForwardedRef } from 'react'
import { css } from '@emotion/react'
import { mediaQueryHookQueries, useMediaQuery } from '..'

interface WrapperProps {
  children?: React.ReactNode
  className?: string
}

export const FlexWrapper: React.FC<WrapperProps> = ({
  children,
  className,
}) => (
  <div
    className={className}
    css={css`
      display: flex;
      flex: 1 1 auto;
      height: 100%;
      overflow: hidden;
      position: relative;
      min-height: 100vh;
    `}
  >
    {children}
  </div>
)

export const SideMenuWrapper: React.FC<WrapperProps> = ({
  children,
  className,
}) => (
  <aside
    className={className}
    css={css`
      display: flex;
      flex: 0 0 auto;
    `}
  >
    {children}
  </aside>
)

export const ContentWrapper = React.forwardRef<HTMLDivElement, WrapperProps>(
  ({ children, className }, ref: ForwardedRef<HTMLDivElement>) => {
    const isMdOver = useMediaQuery(mediaQueryHookQueries.mdOver)

    return (
      <div
        ref={ref}
        className={className}
        css={css`
          display: flex;
          flex: 1 1 auto;
          flex-direction: column;
          overflow-y: auto;
          position: ${isMdOver && 'relative'};
          z-index: 98;
        `}
      >
        {children}
      </div>
    )
  }
)
