/**
 * Direct import of UILIB components is necessary due to an incompatibility with esbuild caused by UILIB's incorrect import of normalize.css
 * UILIB core
 */
import Spacer from '@uilib/core/Spacer'
import { ColumnSizeType } from '@uilib/core/Grid/Column'
import Column from '@uilib/core/Grid/Column/Column'
import Row from '@uilib/core/Grid/Row/Row'
import Grid from '@uilib/core/Grid'
import { useMediaQuery, mediaQueryHookQueries } from '@uilib/core/MediaQuery'
import FlexContainer, { FlexContainerProps } from '@uilib/core/FlexContainer'
import coreConstants from '@uilib/core/_utils/constants'
import { floatingPanelUtils } from '@uilib/core/FloatingPanel'

/**
 * UILIB consumer-components
 */
import App from '@uilib/consumer-components/App'
import Button, { ButtonProps } from '@uilib/consumer-components/Button'
import Headline from '@uilib/consumer-components/Headline'
import LoadingDots from '@uilib/consumer-components/LoadingDots'
import { colorPalette } from '@uilib/consumer-components/_utils/colors'
import consumerConstants from '@uilib/consumer-components/_utils/constants'
import * as utils from '@uilib/consumer-components/_utils'
import Theme, { ThemeType } from '@uilib/consumer-components/_utils/theme'
import FloatingButton from '@uilib/consumer-components/FloatingButton'
import IconMapsGlobe from '@uilib/assets-consumer-icons/IconMapsGlobe'
import Text from '@uilib/consumer-components/Text'
import Divider from '@uilib/consumer-components/Divider'
import FloatingPanel, {
  floatingPanelDefaultMiddleware,
} from '@uilib/consumer-components/FloatingPanel'
import Link from '@uilib/consumer-components/Link'
import ListSelector from '@uilib/consumer-components/ListSelector'
import ListSelectorItemType from '@uilib/consumer-components/ListSelector/ListSelector.types'

// UILIB icons
import IconStatusExclamationTriangleColor from '@uilib/assets-consumer-icons/IconStatusExclamationTriangleColor'
import LogoEhInlineColor from '@uilib/assets-consumer-logos/LogoEhInlineColor'

import { Link as RouterLink } from 'react-router-dom'

const colors = {
  ...colorPalette,
  lightGrey: 'rgba(255, 255, 255, 0.70)',
  errorPrimaryHover: '#b41a08',
  lightBlueBackground: 'rgba(24, 125, 175, 0.10)',
  dropdownBackground: '#ebebeb',
}

const constants = {
  core: coreConstants,
  consumer: consumerConstants,
}

export type {
  ThemeType,
  FlexContainerProps,
  ButtonProps,
  ColumnSizeType,
  ListSelectorItemType,
}

export {
  App,
  Button,
  Headline,
  Spacer,
  Column,
  Grid,
  Row,
  FloatingButton,
  LoadingDots,
  LogoEhInlineColor,
  RouterLink,
  mediaQueryHookQueries,
  useMediaQuery,
  FlexContainer,
  colors,
  constants,
  utils,
  Theme,
  IconMapsGlobe,
  FloatingPanel,
  floatingPanelDefaultMiddleware,
  floatingPanelUtils,
  ListSelector,
  IconStatusExclamationTriangleColor,
  Divider,
  Link,
  Text,
}
