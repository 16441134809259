import { createBrowserRouter, Navigate } from 'react-router-dom'
import routes from 'routes'
import MasterTemplate from 'components/layout/page-templates/MasterTemplate'
import InvalidLinkError from 'features/error/InvalidLinkError'
import DeeplinkValidation from 'features/deeplinkValidation'

const router = createBrowserRouter([
  {
    path: '',
    element: <MasterTemplate />,
    children: [
      {
        path: ':product/:protocol',
        element: <DeeplinkValidation />,
      },
      {
        index: true,
        element: <DeeplinkValidation />,
      },
      {
        path: routes.invalidLink,
        element: <InvalidLinkError appName="EMS" />,
      },
      {
        path: '*',
        element: <Navigate to={routes.invalidLink} />,
      },
    ],
  },
])

export default router
