/**
 * This is a LANGUAGES mapping which is used for
 * parsing XLIFF files into public/locales/ i18n files
 * and setting the moment.js locale after i18n changes.
 *
 * We specify countries for every XLIFF file in uppercase.
 *
 * i18next and moment.js prefers to have the country omitted.
 *
 * moment.js does not support "es-CL" for example, so "es" is used as a fallback.
 *
 */
const languageMap = new Map([
  // XLIFF    i18next     moment.js
  ['ar-EG', ['ar', 'ar']],
  ['bg-BG', ['bg', 'bg']],
  ['ca-ES', ['ca-ES', 'ca']],
  ['cs-CZ', ['cs', 'cs']],
  ['de-DE', ['de', 'de']],
  ['da-DK', ['da', 'da']],
  ['es-CL', ['es-cl', 'es']],
  ['es-ES', ['es', 'es']],
  ['el-GR', ['el', 'el']],
  ['en-US', ['en', 'en']],
  ['en-JM', ['en-jm', 'en']],
  ['et-EE', ['et', 'et']],
  ['fi-FI', ['fi', 'fi']],
  ['fr-CA', ['fr-ca', 'fr-ca']],
  ['fr-FR', ['fr', 'fr']],
  ['he-IL', ['he', 'he']],
  ['hr-HR', ['hr', 'hr']],
  ['hu-HU', ['hu', 'hu']],
  ['hy-AM', ['hy', 'hy-am']],
  ['it-IT', ['it', 'it']],
  ['id-ID', ['id', 'id']],
  ['ja-JP', ['ja', 'ja']],
  ['kk-KZ', ['kk', 'kk']],
  ['ko-KR', ['ko', 'ko']],
  ['lt-LT', ['lt', 'lt']],
  ['lv-LV', ['lv', 'lv']],
  ['nb-NO', ['nb', 'nb']],
  ['nn-NO', ['nb', 'nb']],
  ['nl-NL', ['nl', 'nl']],
  ['pl-PL', ['pl', 'pl']],
  ['pt-BR', ['pt', 'pt']],
  ['ro-RO', ['ro', 'ro']],
  ['ru-RU', ['ru', 'ru']],
  ['sk-SK', ['sk', 'sk']],
  ['sl-SI', ['sl', 'sl']],
  ['sr-Latn-RS', ['sr', 'sr']],
  ['sv-SE', ['sv', 'sv']],
  ['th-TH', ['th', 'th']],
  ['tr-TR', ['tr', 'tr']],
  ['uk-UA', ['uk', 'uk']],
  ['vi-VN', ['vi', 'vi']],
  ['zh-CN', ['zh', 'zh-cn']],
  ['zh-TW', ['zh-tw', 'zh-tw']],
])

export default languageMap
