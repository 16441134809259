import { LoadingDots } from 'components'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import * as styles from './MasterTemplate.styles'
import Header from '../header/Header'
import { ContentWrapper, FlexWrapper } from '../StyledWrappers'

const MasterTemplate = () => (
  <FlexWrapper css={[styles.masterTemplate]}>
    <ContentWrapper css={styles.masterTemplateWrapper()}>
      <Header />
      <div css={styles.masterTemplateContent}>
        <main css={styles.masterTemplateChildren()}>
          <Suspense fallback={<LoadingDots fullScreen withoutOverlay />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </ContentWrapper>
  </FlexWrapper>
)

export default MasterTemplate
