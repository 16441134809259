import { FlexContainer } from '../../..'
import LanguageSelector from '../../language-menu/LanguageSelector'
import * as styles from './HeaderCenteredContent.styles'
import { IconProps } from '../../../base/Icon'

interface Props {
  logo: IconProps['icon']
}

const HeaderCenteredContent = ({ logo }: Props) => (
  <FlexContainer
    align="center"
    justify="center"
    css={[styles.headerCenteredContent]}
  >
    {logo}

    <div css={[styles.headerContentActionBtns]}>
      <LanguageSelector />
    </div>
  </FlexContainer>
)

export default HeaderCenteredContent
