// import envData from './settings-local'
import envData from './settings-dev'
// import envData from './settings-test'
// import envData from './settings-prod'

if (myESET.isLocalEnv !== 'false') {
  myESET.envData = envData
}

export default envData
