import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './env/index'
import { LoadingDots } from 'components'
import App from 'App'
import 'localization/i18n'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingDots fullScreen />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')!
)
