import { css } from '@emotion/react'

export const header = (isMd: boolean) => css`
  z-index: 100;
  position: sticky;
  top: 0;

  padding-inline: ${isMd ? 20 : 26}px;
  padding-block-start: env(safe-area-inset-top);

  // TODO (P_EHOME-18791): remove this once logo from UILIB is used
  .logo-home-beta-white path {
    fill: #000 !important;
  }
`
export const headerBackground = (transparent?: boolean) => css`
  background-color: ${transparent ? 'transparent' : 'rgba(245, 246, 248, 0.9)'};
  backdrop-filter: blur(30px) brightness(100%);
`
export const headerContent = (isXs: boolean) => css`
  min-height: ${isXs ? 56 : 80}px;
  height: ${isXs ? 56 : 80}px;
`
