import LanguageSelector from 'components/layout/language-menu/LanguageSelector'
import { FlexContainer } from '../../..'
import * as styles from './HeaderSplitContent.styles'
import { IconProps } from '../../../base/Icon'

interface Props {
  logo: IconProps['icon']
}

const HeaderSplitContent = ({ logo }: Props) => (
  <FlexContainer
    align="center"
    justify="space-between"
    css={[styles.headerSplitContent]}
  >
    {logo}

    <div>
      <LanguageSelector />
    </div>
  </FlexContainer>
)

export default HeaderSplitContent
