import envData from 'env'
import Bowser from 'bowser'
import { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import routes from 'routes'
import FallbackPage from './components/FallbackPage'

const deeplinkConfigs = new Map<
  string,
  { intentUrl: string; deeplinkFallbackUrl: string }
>([
  [
    'ems/token',
    {
      intentUrl: envData.EMSTokenIntentUrl,
      deeplinkFallbackUrl: envData.EMSTokenFallbackUrl,
    },
  ],
])

const interpolate = (template: string, key: string, value: string): string =>
  template.replace(`{${key}}`, value)

const bowser = Bowser.getParser(window.navigator.userAgent)

const DeeplinkTest = () => {
  const { product, protocol } = useParams()
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [intentUrl, setIntentUrl] = useState<string>('')
  const [deeplinkFallbackUrl, setDeeplinkFallbackUrl] = useState<string>('')
  const [deeplinkUrl, setDeeplinkUrl] = useState<string>('')

  const triggerIntent = (url: string) => {
    const element = document.createElement('a')
    element.href = url

    element.click()
  }

  useEffect(() => {
    if (product && protocol) {
      const deeplinkConfig = deeplinkConfigs.get(`${product}/${protocol}`)

      if (deeplinkConfig) {
        setIntentUrl(
          interpolate(
            deeplinkConfig.intentUrl,
            'tokenData',
            queryParams.get('data') ?? ''
          )
        )

        if (bowser.getOSName(true) === 'android') {
          triggerIntent(intentUrl)
        }

        setDeeplinkUrl(
          encodeURIComponent(
            `${envData.DeeplinkHost}${location.pathname}${location.search}`
          )
        )

        setDeeplinkFallbackUrl(
          interpolate(
            deeplinkConfig.deeplinkFallbackUrl,
            'deeplink',
            deeplinkUrl
          )
        )
      } else {
        navigate(routes.invalidLink)
      }
    } else {
      navigate(routes.invalidLink)
    }
  }, [product, protocol, intentUrl, deeplinkFallbackUrl, deeplinkUrl])

  return (
    <FallbackPage
      deeplinkFallbackUrl={deeplinkFallbackUrl}
      product={product}
      deeplinkUrl={deeplinkUrl}
    />
  )
}

export default DeeplinkTest
