import i18next from "i18next"
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const DEFAULT_LANGUAGE = 'en'

i18next
  // Load translation using http from /wwwroot/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // Learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // Detect user language
  // Learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Init i18next
  // For all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: DEFAULT_LANGUAGE,
    detection: {
      order: [
        'mea-detector',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['localStorage'],
      lookupCookie: 'default_language',
    },
    keySeparator: false,
    interpolation: {
      // React already protects from xss
      escapeValue: false,
    },
    // debug: myESET.isLocalEnv !== 'false',
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: { v: new Date().getUTCDate() },
    },
  })

i18next.on('languageChanged', (lng) => {
  // Change the <html /> direction for the current lang
  document.documentElement.dir = i18next.dir()
  // Change the <html /> lang attr for the current lang
  document.documentElement.lang = lng
})

export default i18next