import { RouterProvider } from 'react-router-dom'
import { App } from 'components'
import AppTheme from 'AppTheme'
import router from 'router'

export default () => (
  <AppTheme>
    <App>
      <RouterProvider router={router} />
    </App>
  </AppTheme>
)
