import { css } from '@emotion/react'
import { utils } from 'components'
import { mqMin } from 'util/style'

export const masterTemplate = css`
  font-size: ${utils.constants.FONT_SIZE_SECONDARY};

  background-color: rgba(245, 246, 248);
`

export const masterTemplateWrapper = () => css`
  overflow: auto;
  ${utils.customScrollbar}
  ${mqMin('MD')} {
    padding-inline-start: 0;
  }
`

export const masterTemplateContent = css`
  display: flex;
  flex-direction: column;
  flex: 1; // Fill up all available vertical space
  padding-inline: 20px;
`

export const masterTemplateBreadcrumbs = css`
  height: 56px;
`

export const masterTemplateChildren = () => {
  const insetBlockSm = '20px'
  const insetBlockStartDefault = '56px'
  const insetBlockEndDefault = '64px'

  return css`
    flex: 1; // Fill up all available vertical space
    padding-block: ${insetBlockSm};

    ${mqMin('SM')} {
      padding-block: ${insetBlockStartDefault} ${insetBlockEndDefault};
    }
  `
}
