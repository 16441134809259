import { IconStatusExclamationTriangleColor, RouterLink } from 'components'
import SimpleCenteredTemplate from 'components/template/SimpleCenteredTemplate'
import i18n from 'localization/i18n'
import { substituteLocale } from 'localization/utils'
import { Trans, useTranslation } from 'react-i18next'
import { errorInvalidLinkButton } from '../../selectors/error.json'

interface InvalidLinkErrorProps {
  appName: string
}

const InvalidLinkError = ({ appName }: InvalidLinkErrorProps) => {
  const { t } = useTranslation()
  
  return (
    <SimpleCenteredTemplate
      size="small"
      title={t('invalidLinkErrorTitle')}
      icon={
        <IconStatusExclamationTriangleColor
          size={84}
          display="inline-block"
        />
      }
      button={{
        component: (
          <RouterLink
            replace
            to={substituteLocale(
              myESET.envData.ContactSupportURL,
              i18n.language
            )}
            data-label={errorInvalidLinkButton}
          >
            {t('InvalidLinkErrorButton')}
          </RouterLink>
        ),
      }}
    >
      <Trans
        i18nKey="invalidLinkErrorDescription"
        components={[<br />]}
        values={{
          appName,
        }}
      />
    </SimpleCenteredTemplate>
  )
}

export default InvalidLinkError
