import React, { ReactElement } from 'react'
import { css } from '@emotion/react'
import {
  Button,
  ButtonProps,
  FlexContainer,
  FlexContainerProps,
  Headline,
  mediaQueryHookQueries,
  Spacer,
  useMediaQuery,
} from '../index'
import { IconProps } from '../base/Icon'
import CenteredLayout, {
  CenteredLayoutProps,
} from '../layout/layout/CenteredLayout'

export interface SimpleCenteredTemplateProps {
  title?: string
  icon?: IconProps['icon']
  button?: ButtonProps
  size: 'small' | 'medium' | 'large'
  titleAlign?: 'center' | 'start' | 'end'
  contentAlign?: FlexContainerProps['justify']
  textAlign?: CenteredLayoutProps['textAlign']
  footer?: ReactElement
}

const FullWidthCenteredFlexContainer = (props: FlexContainerProps) => (
  <FlexContainer
    justify="center"
    css={css`
      width: 100%;
    `}
    {...props}
  />
)

/**
 * Simple layout with optional pre-defined content placeholders - icon, title, buttons.
 * @param buttons - Accepts a single entity or an array. Buttons of a nested array will be rendered in a row.
 * Elements of a flat array will be rendered in a column.
 * Two types of elements are accepted - object with CustomButtonProps, or a React element.
 */
const SimpleCenteredTemplate: React.FC<SimpleCenteredTemplateProps> = ({
  size,
  title,
  icon,
  button,
  titleAlign = 'center',
  children,
  contentAlign = 'center',
  textAlign = 'center',
  footer,
}) => {
  const isXsOnly = useMediaQuery(mediaQueryHookQueries.xsOnly)

  const getButtons = () => (
    <FullWidthCenteredFlexContainer>
      {React.isValidElement(button) ? (
        button
      ) : (
        <Button {...button} fullWidth={isXsOnly} />
      )}
    </FullWidthCenteredFlexContainer>
  )

  return (
    <CenteredLayout
      size={size}
      contentAlign={contentAlign}
      textAlign={textAlign}
    >
      <FlexContainer
        justify="center"
        direction="column"
        css={css`
          ${isXsOnly &&
          css`
            align-items: center;
            flex-grow: 1;
          `}
        `}
      >
        {icon && (
          <FlexContainer justify="center">
            <Spacer
              type="mb-4"
              css={css`
                text-align: center;
              `}
            >
              {icon}
            </Spacer>
          </FlexContainer>
        )}
        {title && (
          <Headline
            type="h2"
            fontWeight={700}
            css={css`
              text-align: ${titleAlign};
              width: 100%;
            `}
          >
            {title}
          </Headline>
        )}
        {children}
      </FlexContainer>
      {button && (
        <Spacer
          type="my-5"
          css={css`
            width: 100%;
          `}
        >
          <FlexContainer>{getButtons()}</FlexContainer>
        </Spacer>
      )}
      {footer && <Spacer type="mb-5">{footer}</Spacer>}
    </CenteredLayout>
  )
}

export default SimpleCenteredTemplate
