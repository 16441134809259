import { css } from '@emotion/react'

export const headerCenteredContent = css`
  position: relative;
  height: 100%;
`

export const headerContentActionBtns = css`
  position: absolute;
  inset-inline-end: 0;
`
