import languageMap from './language-map'
/**
 * Get a key in a JavaScript Map by its value,
 * in other words, get the XLIFF format by the i18n
 * (user's current language).
 *
 * MAPi18n2xliff('fr') -> 'fr-FR'
 * MAPi18n2xliff('zh-tw') -> 'zh-TW'
 *
 * @param {value} i18n lang value
 */
export const MAPi18n2xliff = (userLang: string) => {
  if (languageMap.has(userLang)) {
    return userLang
  }

  const XLIFF = Array.from(languageMap.entries()).find(
    (arr) => arr[1][0] === userLang
  )
  return XLIFF ? XLIFF[0] : 'en-US'
}

/**
 * Replaces <0> tag in a provided link by the mapped locale
 * @param targetLink - link containing <0> tag that needs to be replaced
 * @param locale - current user language in i18next format
 * @returns final link containing substituted locale
 */
export const substituteLocale = (targetLink: string, locale: string): string =>
  targetLink.replace('<0>', MAPi18n2xliff(locale))

// export const MAPi18n2moment = (userLang: string) => {
//   if (languageMap.has(userLang)) {
//     const moment = languageMap.get(userLang)
//     return moment ? moment[1] : 'en'
//   }
//
//   const moment = Array.from(languageMap.entries()).find(
//     (arr) => arr[1][0] === userLang
//   )
//   return moment ? moment[1][1] : 'en'
// }

export const isi18nInsideLanguageMap = (i18nLng: string) =>
  Array.from(languageMap.entries()).findIndex((arr) => arr[1][0] === i18nLng) >
  -1

export const geti18nFromLanguageMap = (i18nLng: string) => {
  const arr = languageMap.get(i18nLng)
  return arr ? arr[0] : undefined
}
