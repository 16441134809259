export const languageNames = new Map<string, string[]>([
  ['ar-EG', ['العربية']],
  ['bg-BG', ['Български']],
  ['ca-ES', ['Català']],
  ['cs-CZ', ['Čeština']],
  ['de-DE', ['Deutsch']],
  ['da-DK', ['Dansk']],
  ['es-CL', ['Español', 'América Latina']],
  ['es-ES', ['Español', 'España']],
  ['el-GR', ['Ελληνικά']],
  ['en-US', ['English']],
  ['en-JM', ['English', 'Jamaica']],
  ['et-EE', ['Eesti']],
  ['fi-FI', ['Suomi']],
  ['fr-CA', ['Français', 'Canadien']],
  ['fr-FR', ['Français', 'France']],
  ['he-IL', ['עברית']],
  ['hr-HR', ['Hrvatski']],
  ['hu-HU', ['Magyar']],
  ['hy-AM', ['Հայերեն']],
  ['it-IT', ['Italiano']],
  ['id-ID', ['Bahasa Indonesia']],
  ['ja-JP', ['日本語']],
  ['kk-KZ', ['Қазақ тілі']],
  ['ko-KR', ['한국어']],
  ['lt-LT', ['Lietuvių']],
  ['lv-LV', ['Latviešu']],
  ['nb-NO', ['Norsk']],
  ['nl-NL', ['Nederlands']],
  ['pl-PL', ['Polski']],
  ['pt-BR', ['Português', 'Brasil']],
  ['ro-RO', ['Română']],
  ['ru-RU', ['Русский']],
  ['sk-SK', ['Slovenčina']],
  ['sl-SI', ['Slovenščina']],
  ['sr-Latn-RS', ['Srpski']],
  ['sv-SE', ['Svenska']],
  ['th-TH', ['ภาษาไทย']],
  ['tr-TR', ['Türkçe']],
  ['uk-UA', ['Українська']],
  ['vi-VN', ['Tiếng Việt']],
  ['zh-CN', ['中文', '简体']],
  ['zh-TW', ['中文', '繁體']],
])
