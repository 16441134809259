import { css } from '@emotion/react'

export const headerSplitContent = css`
  position: relative;
  height: 100%;
`

export const headerContentSideMenuBtn = css`
  min-width: auto;
  padding: 8px;
  margin-inline-start: -8px;
`
