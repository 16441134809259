import { colors, Theme, ThemeType } from 'components'
import { PropsWithChildren } from 'react'

// App theme
const appTheme: ThemeType = {
  Global: {
    fontFamily: '"Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  Text: {
    color: colors.grey.c800,
  },
  Headline: {
    color: colors.grey.c800,
    fontWeight: 'bold',
  },
}

const AppTheme = ({ children }: PropsWithChildren<unknown>) => (
  <Theme.Provider theme={appTheme}>{children}</Theme.Provider>
)

export default AppTheme
