import { useState } from 'react'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { languageNames } from 'localization/languageNames'
import { debounce } from 'lodash'
import {
  FloatingButton,
  IconMapsGlobe,
  mediaQueryHookQueries,
  useMediaQuery,
  FloatingPanel,
  floatingPanelUtils,
  ListSelector,
  floatingPanelDefaultMiddleware,
} from '../..'

const LanguageSelector = () => {
  const { t, i18n } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectorHeight, setSelectorHeight] = useState<number>()

  const isMobileView = useMediaQuery(mediaQueryHookQueries.md)

  const setMaxHeightDebounce = debounce(setSelectorHeight, 50)

  const getLanguageOptions = () => {
    const languageOptions = Array.from(languageNames).map(([key, value]) => ({
      value: key,
      name: value[0],
      subName: value[1],
      testDataLabel: key,
    }))
    return languageOptions.sort((a, b) => a.name.localeCompare(b.name))
  }

  const handleLanguageChange = (languageCode?: string) => {
    if (languageCode) {
      i18n
        .changeLanguage(languageCode)
        .catch(() => console.log('Language change failed by i18n'))
        .finally(() => {
          setIsOpen(false)
        })
    }
  }

  return (
    <FloatingPanel
      isPortaled
      arrow="wide"
      css={css`
        border-radius: 10px;
        margin-top: 10px;
        width: 432px;
        @media (max-width: 432px) {
          width: 100%;
        }
      `}
      onOpenChange={() => setIsOpen(!isOpen)}
      open={isOpen}
      middleware={[
        ...floatingPanelDefaultMiddleware,
        floatingPanelUtils.size({
          apply({ availableHeight }) {
            setMaxHeightDebounce(availableHeight - 12)
          },
        }),
      ]}
      reference={
        <FloatingButton
          icon={<IconMapsGlobe />}
          css={css`
            margin: 0 8px;
            font-weight: normal;
          `}
          text={isMobileView ? '' : languageNames.get(i18n.language)?.[0]}
          testDataLabel="languageSelector-toggle-btn"
          onClick={() => setIsOpen(!isOpen)}
        />
      }
    >
      <ListSelector
        header={t('LanguageSelectHeader')}
        css={css`
          padding-inline-end: 10px;
        `}
        items={getLanguageOptions()}
        selectedItem={i18n.language}
        onItemClick={(item) => handleLanguageChange(item?.value)}
        testDataLabel="languageSelector"
        maxHeight={selectorHeight}
      />
    </FloatingPanel>
  )
}

export default LanguageSelector
