import { css } from '@emotion/react'
import React from 'react'

export interface IconProps {
  icon: React.ReactElement | { src: string; alt: string }
  size: number
}

const Icon: React.FC<IconProps> = ({ icon, size }) => {
  if (React.isValidElement(icon)) {
    const IconComponent = icon.type
    const props = typeof icon.props === 'object' ? icon.props : {}

    // Size prop is only present in UILIB icons, logos use height prop instead
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return 'size' in (props as any) ? (
      <IconComponent {...props} size={size} />
    ) : (
      <IconComponent {...props} height={size} />
    )
  }

  if (typeof icon === 'object' && 'src' in icon)
    return (
      <img
        src={icon.src}
        alt={icon.alt}
        css={css`
          width: 100%;
          max-width: ${size}px;
        `}
      />
    )

  return null
}

export default Icon
