import React from 'react'
import { css } from '@emotion/react'
import {
  Column,
  ColumnSizeType,
  FlexContainer,
  FlexContainerProps,
  Grid,
  Row,
} from '../../index'

type TemplateSize = 'large' | 'medium' | 'small'

type SizeMap = Record<
  'xl' | 'lg' | 'md' | 'sm',
  {
    [size in TemplateSize]: ColumnSizeType
  }
>

const sizeMap: SizeMap = {
  xl: { large: 8, medium: 6, small: 4 },
  lg: { large: 10, medium: 8, small: 6 },
  md: { large: 12, medium: 8, small: 8 },
  sm: { large: 12, medium: 12, small: 12 },
}

export interface CenteredLayoutProps {
  size: TemplateSize
  contentAlign?: FlexContainerProps['justify']
  textAlign?: 'center' | 'start' | 'end'
  className?: string
}

const CenteredLayout: React.FC<CenteredLayoutProps> = ({
  size,
  contentAlign = 'center',
  textAlign = 'start',
  children,
  className,
}) => (
  <Grid
    className={className}
    css={css`
      height: 100%;
    `}
  >
    <Row
      css={css`
        height: 100%;
      `}
    >
      <Column size="equal" />
      <Column
        xl={sizeMap.xl[size]}
        lg={sizeMap.lg[size]}
        md={sizeMap.md[size]}
        sm={sizeMap.sm[size]}
        css={css`
          height: 100%;
        `}
      >
        <FlexContainer
          direction="column"
          justify={contentAlign}
          align="center"
          css={css`
            height: 100%;
            text-align: ${textAlign};
          `}
        >
          {children}
        </FlexContainer>
      </Column>
      <Column size="equal" />
    </Row>
  </Grid>
)

export default CenteredLayout
